import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { theme } from './theme/MUItheme';
import HomeScreen from './views/HomeScreen';
import AdminScreen from './views/AdminScreen';
import BookScreen from './views/BookScreen';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          <Route path='/:weddingId/admin' element={<AdminScreen />} />
          <Route path='/:weddingId/book' element={<BookScreen />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
