import { Box, Grid } from "@mui/material";
import { AiFillGift } from 'react-icons/ai';
import { colors } from "../constants/colors";
import { variables } from "../constants/network";
import Subtitles from "../organims/Subtitles";
import { openLink } from "../Utils/Utilities";

const GiftTable = () => {

    const liverpool = require('../images/liverpool.png');
    const palacioDeHierroLogo = require('../images/palacio_de_hierro.png');

    return (
        <Grid marginTop={5}>
            <Box
                display={'flex'} 
                flexDirection={'row'} 
                justifyContent={'center'}
                alignItems={'center'}>
                <AiFillGift color={colors.dorado} size={40} />
                <Subtitles text='Mesa de Regalos' />
            </Box>

            <Box marginTop={2} marginLeft={3} marginRight={3} textAlign={'center'}>
                Lo más importante para nosotros es que nos acompañen en este gran momento de nuestras vidas; Si desean hacernos un regalo, 
                estaremos muy agradecidos
            </Box>

            <Box paddingTop={3} sx={{ flexGrow: 1 }}  alignItems="center">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                        <Box 
                            className="cursor" 
                            justifyContent={'center'}
                            textAlign={'center'}
                            onClick={() => openLink(`${variables.liverpool.gitfUrl}/${variables.liverpool.giftTableId}`)}>
                            <img src={liverpool} 
                                alt='Mesa de regalos Liverpool'
                                style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}} />
                            <Box>CÓDIGO: {variables.liverpool.giftTableId}</Box>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                        <Box 
                            className="cursor" 
                            justifyContent={'center'}
                            textAlign={'center'}
                            onClick={() => openLink(`${variables.palacioHierro.gitfUrl}${variables.palacioHierro.giftTableId}`)}>
                            <img src={palacioDeHierroLogo} 
                                style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}} />
                            <Box>CÓDIGO: {variables.palacioHierro.giftTableId}</Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

export default GiftTable;