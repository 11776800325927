import { Box } from "@mui/system";
import { GiAmpleDress } from 'react-icons/gi';
import { RiShirtFill } from 'react-icons/ri';

import { colors } from "../constants/colors";

import Subtitles from "../organims/Subtitles";

const DressCode = () => {

    const womenDressCode = require('../images/woman-dress-code.png');
    const manDressCode = require('../images/man-dress-code.png');

    return (
        <Box marginTop={5}>
            <Box
                display={'flex'} 
                flexDirection={'row'} 
                justifyContent={'center'}
                alignItems={'center'}>
                <GiAmpleDress color={colors.dorado} size={40} />
                <Subtitles text='&nbsp;Código de vestimenta&nbsp;' />
                <RiShirtFill color={colors.dorado} size={40} />
            </Box>
            
            
            <Box textAlign={'center'} fontSize={25}>Formal</Box>

            <Box flexDirection={'row'} display={'flex'}>
                <Box>
                    <img width={'80%'} src={womenDressCode} 
                        alt='Código de vestimenta para mujeres'
                        style={{boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}} />
                    {/* <Box textAlign={'center'}>
                        <Box>* No vestido blanco</Box>
                        <Box>* Vestido largo</Box>
                    </Box> */}
                </Box>

                <Box>
                    <img width={'80%'} src={manDressCode} 
                        alt='Código de vestimenta para hombres'
                        style={{boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}} />
                    {/* <Box textAlign={'center'}>
                        <Box>* Traje</Box>
                        <Box>* Corbata</Box>
                    </Box> */}
                </Box>
            </Box>
        </Box>
    );
}

export default DressCode;