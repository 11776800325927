import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const LoadingWest = ({text = 'Un momento, estamos buscándo la invitación'}) => {

    return (
        <Box textAlign={'center'}>
            <CircularProgress style={{color: '#ea1d84'}} />
            <Box>{text}</Box>
        </Box>
    );

}

export default LoadingWest;