import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import { Box } from "@mui/system";
import { MdHistoryEdu } from 'react-icons/md';
import { colors } from "../constants/colors";

import Subtitles from "../organims/Subtitles";
import '@coreui/coreui/dist/css/coreui.min.css'

const History = () => {

    const history1 = require('../images/history1.jpg');
    const history2 = require('../images/history2.jpg');
    const history3 = require('../images/history3.jpg');

    return (
        <Box>
            <Box
                display={'flex'} 
                marginTop={5}
                flexDirection={'row'} 
                justifyContent={'center'}
                alignItems={'center'}>
                <MdHistoryEdu color={colors.dorado} size={40} />
                <Subtitles text={'Nuestra Historia'} />
            </Box>
            <center>
                <CCarousel 
                    style={{width: 369, boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}} 
                    interval={3500} controls indicators>
                    <CCarouselItem >
                        <CImage className="d-block w-100" src={history3} alt="Imagen 1" />
                    </CCarouselItem>

                    <CCarouselItem>
                        <CImage className="d-block w-100" src={history2} alt="Image 2" />
                    </CCarouselItem>

                    <CCarouselItem>
                        <CImage className="d-block w-100" src={history1} alt="Image 3" />
                    </CCarouselItem>
                </CCarousel>
            </center>

            <Box textAlign={'center'} marginTop={3} marginLeft={3} marginRight={3}>
                Hace poco más de 4 años nuestros caminos se cruzaron, y sin saber lo que nos deparaba el destino comenzamos a conocernos,
                poco a poco nos dimos cuenta de que teníamos cosas que nos complementaban, comenzamos a compartir momentos, experiencias, alegría y 
                nuestro amor fue creciendo, fue entonces cuándo nos dimos cuenta de que no queríamos estar uno sin el otro y en Marzo de 2022 
                con una propuesta y un Sí, decidimos comenzar una vida y una familia juntos.
            </Box>

        </Box>
    );

}

export default History;