import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { GiLinkedRings } from 'react-icons/gi';
import Subtitles from '../organims/Subtitles';
import LoadingWest from '../organims/Loading';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { BsCalendarEventFill } from 'react-icons/bs';
import { FaChurch } from 'react-icons/fa';

import { GiMeal, GiStairsCake, GiFinishLine } from 'react-icons/gi';

import Typography from '@mui/material/Typography';
import { colors } from '../constants/colors';

const BigDay = ({loading, guest}) => {

  const Icon = ({name}) => {
    if(name === 'FaChurch') {
      return <FaChurch />
    } else if (name === 'BsCalendarEventFill') {
      return <BsCalendarEventFill />
    } else if(name === 'GiMeal') {
      return <GiMeal />
    } else if(name === 'GiStairsCake') {
      return <GiStairsCake />
    } else if(name === 'GiFinishLine') {
      return <GiFinishLine />
    } else {
      return null;
    }
  }

  const ProgramItem = ({hour = '', title = '', subtitle = '', color = 'primary', variant = '', icon = ''}) => {
    return (
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary">
          {hour}
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color={color} variant={variant}>
            <Icon name={icon} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography fontWeight={'bold'} component="span">{title}</Typography>
          <Typography>{subtitle}</Typography>
        </TimelineContent>
      </TimelineItem>
    );
  }

  return (
    <Box marginTop={5}>
      <Box
        display={'flex'} 
        flexDirection={'row'} 
        justifyContent={'center'}
        alignItems={'center'}>
          <GiLinkedRings color={colors.dorado} size={40} />
          <Subtitles text='Nuestro gran dia' />
      </Box>

      {loading && 
        <LoadingWest />
      }

      {( !loading && guest.paxes > 0 ) &&
        <Grid textAlign={'center'} marginLeft={3} marginRight={3}>
          {guest?.name} es un gusto para nosotros invitarte a nuestra Boda, hemos reservado {guest?.paxes} pases para que estes 
          con nosotros en esta fecha tan importante.
        </Grid>
      } 

      <Box textAlign={'center'} paddingTop={10}>
        <Box fontFamily={'Omnes'} fontSize={25}>
          Con la bendición de nuestros padres
        </Box>

        <Box paddingTop={3} sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={6} sm={4} md={6}>
              <Box>
                <Box fontWeight={'bold'}>Padre y madre de la novia</Box>
                <Box>Ernesto Gonzalez García</Box>
                <Box>Araceli Buenrostro García</Box>
              </Box>
            </Grid>

            <Grid item xs={6} sm={4} md={6}>
              <Box>
                <Box fontWeight={'bold'}>Padre y madre del novio</Box>
                <Box>Alvaro García Jiménez</Box>
                <Box>Josefina Sánchez Espinoza</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Timeline position={'alternate'}>
        <ProgramItem 
          hour={'06:00 PM'} 
          title={'Ceremonia Religiosa'}
          color={'primary'}
          variant={'filled'}
          icon={'FaChurch'}
          subtitle={'Parroquía Del Señor Campo Florida'} />

        <ProgramItem 
          hour={'07:45 PM'} 
          title={'Recepción de Banquete'}
          color={'secondary'}
          variant={'filled'}
          icon={'BsCalendarEventFill'}
          subtitle={'Jardín Selva Negra'} />

        <ProgramItem 
          hour={'01:00 AM'} 
          title={'Cierre de Celebración'}
          color={'primary'}
          icon={'GiFinishLine'}
          variant={'filled'} />

    </Timeline>
    </Box>
  );
}

export default BigDay;
