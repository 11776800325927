import { Box } from "@mui/system";

const Subtitles = ({text = '', size=30}) => {

    return (
        <Box 
            textAlign={'center'}
            fontSize={size}
            fontWeight={'bold'}
            fontFamily={'Brittany Signature'}>
            {text}
        </Box>
    );

}

export default Subtitles;