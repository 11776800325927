import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { openLink } from '../Utils/Utilities';
import { variables } from '../constants/network';

const Ceremonias = () => {
    
    const mapsImg = require('../images/maps.png');
    const salonSelvaNegra = require('../images/salon_selva_negra.png');
    const iglesia = require('../images/iglesia.png');

    return (
        <Box paddingTop={3} sx={{ flexGrow: 1 }}  alignItems="center">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                    <b>Ceremonia 06:00 P.M.</b>
                    <br />
                    <Box height={220}>
                        Parroquia Del Señor Campo Florida <br />
                        <img src={iglesia} style={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'}} />
                    </Box>
                        
                    <div style={{paddingTop: 10, cursor: 'pointer'}} onClick={() => openLink(variables.locations.church)}>
                        <img src={mapsImg} width={50} height={50} /><br />
                        Ver en mapa
                    </div>
                </Grid>

                <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                    <b>Banquete 08:00 P.M.</b> <br />
                    
                    <Box height={220}>
                        <span>Jardín Selva Negra</span> <br />
                        <img src={salonSelvaNegra} style={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'}} />
                    </Box>

                    <div style={{paddingTop: 10}} onClick={() => openLink(variables.locations.lounge)}>
                        <img className='cursor' src={mapsImg} width={50} height={50} /><br />
                        <span className='cursor'>Ver en mapa</span>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Ceremonias;