import { Button, Grid, TextareaAutosize } from "@mui/material";
import { GiConfirmed } from 'react-icons/gi';
import { Box } from "@mui/system";
import Subtitles from "../organims/Subtitles";
import styled from "@emotion/styled";
import { purple } from "@mui/material/colors";
import { useState } from "react";
import { variables } from "../constants/network";
import LoadingWest from "../organims/Loading";
import { colors } from "../constants/colors";

const Confirm = ({weddingId, loading, guest = undefined, findGuestParam = () => {}}) => {

    const [text, setText] = useState('');
    const [loadingConfirm, setLoadingConfirm] = useState(false);

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
          backgroundColor: purple[700],
        },
    }));

    const confirmReject = ({confirm = 0}) => {
        let dataConfirm = {
            confirm,
            weddingId,
            guestId: guest.id,
            message: text
        };

        setLoadingConfirm(true);

        fetch(`${variables.api}/wedding/${weddingId}/guests/${guest.id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataConfirm)
        }).then(response => {
            setText('');
            findGuestParam();
            setLoadingConfirm(false);
        }).catch(errro => {
            setLoadingConfirm(false);
        });
    }

    return (
        <Grid marginTop={5} textAlign={'center'}>

            <Box
                display={'flex'} 
                flexDirection={'row'} 
                justifyContent={'center'}
                alignItems={'center'}>
                <GiConfirmed color={colors.dorado} size={40} />
                <Subtitles text={'Asistencia'} />
            </Box>

            <Box marginTop={2} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                {(guest.accept === null || guest.accept === undefined) && 
                    <Box>
                         <Box>
                            Por favor, confirmanos tu asistencia, si tienes alguna duda, no dudes en contactarnos
                        </Box>
                        <Box>Puedes dejarnos un mensaje en nuestro libro de firmas</Box>
                    </Box>
                }

                {(guest.accept !== null && guest.accept !== undefined) && 
                    <Box>{guest.accept ? 'Es un gusto para nosotros que puedas acompañarnos' : 'Sentimos mucho que no puedas acompañarnos'}</Box>        
                }

                {(guest.paxes > 0 && (guest.accept === null || guest.accept === undefined )) && 
                    <>
                        {loading && 
                            <LoadingWest />
                        }

                        {!loading &&
                            <TextareaAutosize 
                                value={text}
                                onChange={(event) => setText(event.target.value)}
                                maxRows={10} minRows={5} />
                        }
                    </>
                }
            </Box>

            {loadingConfirm && 
                <LoadingWest text="Un momento, estamos registrando tu respuesta" />
            }

            {loading && <LoadingWest />}
            
            { ( !loading && (!loadingConfirm && (guest.paxes > 0 && (guest.accept === null || guest.accept === undefined ))  )) && 
                <Box marginTop={2} display={'flex'} justifyContent={'center'}>
                    <ColorButton variant="contained" onClick={() => confirmReject({confirm: 1})}>
                        Asistiré
                    </ColorButton>

                    <Box width={10} height={10} />
                
                    <Button variant="contained" color="secondary" onClick={() => confirmReject({confirm: 0})}>
                        No asistiré
                    </Button>
                </Box>
            }
            
        </Grid>
    );
}

export default Confirm;