export const colors = {
  black: '#000',
  white: '#ffffff',
  dark: '#333333',
  light: '#f5f5f5',
  green: '#0EB202',
  blue: '#2E86C1',
  orange: '#ff6600',
  lightOrange: '#f7e6e6',
  red: 'red',
  translucentDark: 'rgba(0,0,0,0.5)',
  translucentLight: 'rgba(255,255,255,0.79)',
  translucentDarkPrimary: 'rgba(63,81,90,0.79)',
  primary: '#9c27b0',
  secondary: '#3F515A',
  error: 'error',
  lightBlue: '#c9f7f5',
  gray: '#909497',
  dorado: '#eeae62',
  lightGray: '#ECECEC',
  darkGray: '#707070',
};
