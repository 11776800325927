import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import Counter from '../components/Counter';
import PauCarlos from '../components/PauCarlos';
import { colors } from '../constants/colors';
import { variables } from '../constants/network';
import Subtitles from '../organims/Subtitles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Menu from '../components/Menu';

const BookScreen = () => {

    const Background = require('./../images/tuyyo3.png');
    const [signatures, setSignatures] = useState([]);
    const { weddingId } = useParams();

    useEffect(() => {
        findSignatures();
    }, []);

    const findSignatures = () => {
        fetch(`${variables.api}/admin/${weddingId}/guests`)
        .then(response => response.json())
        .then(guests => {

            fetch(`${variables.api}/admin/${weddingId}/signatures`)
            .then(response => response.json())
            .then(data => {
                data.map(v => {
                v.date = format(new Date(v.date), "dd/MM/yyyy HH:mm 'hrs'", { locale: esLocale });
                v.guest = guests.find(g => g.id === v.guestId).name;
            })
            setSignatures(data);
            }).catch(errro => {
                setSignatures([]);
            });

        });
    }

    return (
        <Grid 
            justifyContent="center"
            justify="center"
            alignItems="center"
            flexDirection={'column'}
            display="flex">

            <Box textAlign={'center'} 
                style={{backgroundImage: `url(${Background})`, 
                backgroundSize: 'auto',
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top'}}>
                
                <PauCarlos />

                <Grid fontFamily={'Omnes'} style={{color: '#FFF'}}  fontSize={25}>
                    <Box fontFamily={'Playlist'} fontSize={45}>25/03/2023</Box>
                    <Box fontFamily={'Playlist'} fontSize={45}>Nuestra boda</Box>
                </Grid>

                <Box paddingTop={12} paddingBottom={11} />
                <Grid marginTop={10} style={{borderBottomWidth: 1, width: '100%', height: 5, backgroundColor: colors.dorado}} />
                <Counter />
            </Box>

            <Menu weddingId={weddingId} />

            <Box paddingLeft={5} paddingRight={5} paddingTop={5} paddingBottom={5}>
                <Subtitles text={`Nuestro Libro de firmas`} size={50} />
                <br />

                <Box paddingTop={3} sx={{ flexGrow: 1 }}  alignItems="center">
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {signatures.length > 0 && 
                            signatures.map((item, index) => (
                                <Card key={`item-${index}`} sx={{ minWidth: 275, width: 300 }} style={{marginBottom: 5,  marginLeft: 5, marginRight: 5}} >
                                    <CardContent>
                                        <Typography variant="h6" component="div">{item.guest}</Typography>
                                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.secondary">{item.date}</Typography>
                                        <Typography variant="body2">{item.message}</Typography>
                                    </CardContent>
                                </Card>
                            ))
                        }
                    </Grid>
                </Box>

            </Box>

        </Grid>
    );

}

export default BookScreen;