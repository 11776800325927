import { Box } from "@mui/material";

const PauCarlos = ({fontSize= 60}) => {

    return (
        <Box paddingBottom={10} style={{fontFamily: 'Playlist', color: '#FFF', fontSize}}>
            Paulina y Carlos
        </Box>
    );
}

export default PauCarlos;