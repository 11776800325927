import { createTheme } from '@mui/material/styles';
import { colors } from './../constants/colors';

export const theme = createTheme({
  overrides: {
    // coloca aqui los estilos globales para los elementos de Material UI
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: colors.orange,
        color: colors.white,
      },
    },
    MuiStepConnector: {
      active: {
        borderColor: colors.orange,
      },
      completed: {
        borderColor: colors.green,
      },
    },
    // BOTONES
    MuiButton: {
      root: {
        borderRadius: 20,
        fontSize: 11,
        letterSpacing: 1,
        paddingTop: 3,
        paddingBottom: 3,
      },
      outlinedPrimary: {
        paddingTop: 0,
        paddingBottom: 0,
        borderWidth: 2,
        color: colors.white,
        backgroundColor: colors.orange,
        borderColor: colors.orange,
        '&:hover': {
          backgroundColor: colors.orange,
          borderColor: colors.primary,
          borderWidth: 3,
        },
      },
      outlinedSecondary: {
        paddingTop: 0,
        paddingBottom: 0,
        color: colors.primary,
        borderWidth: 2,
        borderColor: colors.primary,
        '&:hover': {
          borderWidth: 2,
        },
      },
      text: {
        borderRadius: 0,
        textTransform: 'none',
        fontSize: 'initial',
        fontWeight: 'initial',
        textAlign: 'initial',
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
});
