import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';

const Menu = ({weddingId = ''}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const goToGuest = () => {
        if(location.pathname.endsWith('book'))
            navigate(`/${weddingId}/admin`);
    }

    const goToBook = () => {
        if(location.pathname.endsWith('admin'))
            navigate(`/${weddingId}/book`);
    }

    const getVariant = (site) => {
        return location.pathname.endsWith(site) ? 'contained' : 'outlined';
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', paddingRight: 10, paddingLeft: 10, marginTop: 5, width:'100%' }}>
            <Button variant={getVariant('admin')}  onClick={() => goToGuest()} fullWidth>Lista de Invitados</Button>
            <Button variant={getVariant('book')} onClick={() => goToBook()} fullWidth style={{marginLeft: 10}}>Libro de firmas</Button>
        </Box>
    );

}

export default Menu;