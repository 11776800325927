import './../App.css';
import Ceremonias from './../components/Ceremonias';
import Counter from './../components/Counter';
import PauCarlos from './../components/PauCarlos';
import { Grid } from '@mui/material';
import Confirm from './../components/Confirm';
import GiftTable from './../components/GiftTable';
import DressCode from './../components/DressCode';
import History from './../components/History';
import BigDay from './../components/BigDay';
import { useEffect, useState } from 'react';
import { variables } from './../constants/network';
import { Box } from '@mui/system';
import { colors } from './../constants/colors';
import Hoteles from '../components/Hoteles';

const HomeScreen = () => {
    
    const [weddingId, setWeddingId] = useState('');
    const [loading, setLoading] = useState(false);
    const [guest, setGuest] = useState({});

    useEffect(() => {
        findGuestParam()
      }, []);
    
      const findGuestParam = () => {
        const queryParams = new URLSearchParams(window.location.search);
        var weddingId = queryParams.get("p1");
        var guestIdParam = queryParams.get("p2");
    
        setWeddingId(weddingId);
    
        if(weddingId === '' || weddingId === '') {
          return;
        }
    
        setLoading(true);
        fetch(`${variables.api}/wedding/${weddingId}/guests/${guestIdParam}`)
          .then(response => response.json())
          .then(data => {
            setLoading(false);
            setGuest(data);
          }).catch(errro => {
            setLoading(false);
          });
      }
    
    const Background = require('./../images/tuyyo3.png');

    return (
        <Grid 
        justifyContent="center"
        justify="center"
        alignItems="center"
        flexDirection={'column'}
        display="flex">
        
        <Box textAlign={'center'} 
          style={{backgroundImage: `url(${Background})`, 
            backgroundSize: 'auto',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'}}>
            
          <PauCarlos />
          
          <Grid fontFamily={'Omnes'} style={{color: '#FFF'}}  fontSize={25}>
            <Box fontFamily={'Playlist'} fontSize={45}>25/03/2023</Box>
            <Box fontFamily={'Playlist'} fontSize={45}>Nuestra boda</Box>
          </Grid>

          <Box paddingTop={12} paddingBottom={11} />
          <Grid marginTop={10} style={{borderBottomWidth: 1, width: '100%', height: 5, backgroundColor: colors.dorado}} />
          <Counter />
        </Box>

        <BigDay loading={loading} guest={guest} />

        <Ceremonias />

        <History />

        <DressCode />

        { (guest && guest.hotel === 1) &&
          <Hoteles />
        }

        <Confirm 
          loading={loading} 
          weddingId={weddingId} 
          findGuestParam={() => findGuestParam()}
          guest={guest} />

        <GiftTable />
        
      </Grid>
    );

}

export default HomeScreen;