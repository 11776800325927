import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
    const countDownDate =  new Date(targetDate);
    
    const [countDown, setCountDown] = useState(
      countDownDate - new Date().getTime()
    );
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);
  
      return () => clearInterval(interval);
    }, [countDownDate]);
  
    return getReturnValues(countDown);
  };
  
  const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  
    return [days, hours, minutes, seconds];
  };
  
export { useCountdown };

const Counter = () => {

    const [days, hours, minutes, seconds] = useCountdown(1679788800000);

    return (
      <Grid width={'100%'}
        style={{background: 'rgba(40, 50, 61, .62', color: '#FFF', paddingTop: 15, paddingBottom: 15, paddingLeft: 20, paddingRight: 20}} 
        flexDirection={'row'} 
        fontWeight={'bold'}
        fontSize={20}
        justifyContent={'center'}
        display={'flex'}>
        
        <Box paddingRight={3}>
          <Box textAlign={'center'}>{days}</Box>
          <Box textAlign={'center'}>Días</Box>
        </Box>

        <Box paddingRight={3}>
          <Box textAlign={'center'}>{hours}</Box>
          <Box textAlign={'center'}>Horas</Box>
        </Box>

        <Box paddingRight={3}>
          <Box textAlign={'center'}>{minutes}</Box>
          <Box textAlign={'center'}>Minutos</Box>
        </Box>

        <Box>
          <Box textAlign={'center'}>{seconds}</Box>
          <Box textAlign={'center'}>Segundos</Box>
        </Box>
      </Grid>
    );
}

export default Counter;