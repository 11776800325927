export const variables = {
    liverpool: {
        gitfUrl: 'https://mesaderegalos.liverpool.com.mx/milistaderegalos',
        giftTableId: '51011500',
    },
    palacioHierro: {
        gitfUrl: 'https://www.elpalaciodehierro.com/buscar?eventId=',
        giftTableId: '373555'
    },
    api: 'https://weeddingcook.herokuapp.com/ws-wedding-cookie/api/v1',
    locations: {
        church: 'https://goo.gl/maps/Gt1y8BYqFfoDNENN7',
        lounge: 'https://goo.gl/maps/pUveerT8dCBrQ1wA9'
    }
};
