import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import Counter from '../components/Counter';
import PauCarlos from '../components/PauCarlos';
import { colors } from '../constants/colors';
import { variables } from '../constants/network';
import Subtitles from '../organims/Subtitles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AiFillCheckCircle } from 'react-icons/ai';
import { AiFillCloseCircle } from 'react-icons/ai';
import { AiFillGift } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import Menu from '../components/Menu';

const AdminScreen = () => {

    const Background = require('./../images/tuyyo3.png');
    const [guests, setGuests] = useState([]);
    const [assist, setAssist] = useState(0);
    const [noAssist, setNoAssist] = useState(0);
    const { weddingId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        findGuests();
    }, []);

    const findGuests = () => {
        fetch(`${variables.api}/admin/${weddingId}/guests`)
        .then(response => response.json())
        .then(data => {
            let confirms = data;
            confirms.map(v => {
                v.dateConfirmInt = new Date(v.dateConfirm).getTime();
                v.accept = v.accept === null ? '-' : (v.accept ? 'Si' : 'No');
                if(v.dateConfirm != null) {
                    v.dateConfirm = format(new Date(v.dateConfirm), "dd/MM/yyyy HH:mm 'hrs'", { locale: esLocale });
                } else  {
                    v.dateConfirm = '-';
                }
            });

            setAssist(confirms.filter(v => v.accept === 'Si').map(v => v.paxes).reduce((a,b) => a + b, 0));
            setNoAssist(confirms.filter(v => v.accept === 'No').map(v => v.paxes).reduce((a,b) => a + b, 0));

            confirms.sort((a,b) => {
                return b.dateConfirmInt - a.dateConfirmInt;
            });

            setGuests(confirms);
        }).catch(errro => {
            setGuests([]);
        });
    }

    return (
        <Box>
            <Grid 
                justifyContent="center"
                justify="center"
                alignItems="center"
                flexDirection={'column'}
                display="flex">

                <Box textAlign={'center'} 
                    style={{backgroundImage: `url(${Background})`, 
                    backgroundSize: 'auto',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top'}}>
                    
                    <PauCarlos />

                    <Grid fontFamily={'Omnes'} style={{color: '#FFF'}}  fontSize={25}>
                        <Box fontFamily={'Playlist'} fontSize={45}>25/03/2023</Box>
                        <Box fontFamily={'Playlist'} fontSize={45}>Nuestra boda</Box>
                    </Grid>

                    <Box paddingTop={12} paddingBottom={11} />
                    <Grid marginTop={10} style={{borderBottomWidth: 1, width: '100%', height: 5, backgroundColor: colors.dorado}} />
                    <Counter />
                </Box>

                <Menu weddingId={weddingId} />

                <Box paddingTop={5} paddingBottom={5}>
                    <Subtitles text={`Invitados a la boda`} size={50} />
                    <Box>
                        <Box fontFamily={'Omnes'} fontSize={20}>{`${guests.map(v => v.paxes).reduce((a,b) => a + b, 0)} Total de personas invitadas`}</Box> <br />
                        <Box fontFamily={'Omnes'} fontSize={20}>{`${assist} Personas Asistirán`}</Box>
                        <Box fontFamily={'Omnes'} fontSize={20}>{`${noAssist} Personas no asistirán`}</Box>
                        <Box fontFamily={'Omnes'} fontSize={20}>{`${guests.map(v => v.paxes).reduce((a,b) => a + b, 0) - assist - noAssist} Personas pendientes de confirmar`}</Box>
                    </Box>
                </Box>

            </Grid>
        
            <Box paddingTop={5} paddingBottom={5}  justifyContent="center"
                justify="center" display="flex"
                alignItems="center">
                <Paper sx={{ overflow: 'scroll', overflowY: 'scroll' }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontFamily: 'Omnes', fontSize: 20}}>Invitado</TableCell>
                                    <TableCell align="center" style={{fontFamily: 'Omnes', fontSize: 20}}>Confirmación</TableCell>
                                    <TableCell style={{fontFamily: 'Omnes', fontSize: 20}}>Fecha Confirmación</TableCell>
                                    <TableCell align="center" style={{fontFamily: 'Omnes', fontSize: 20}}>Pax</TableCell>
                                    <TableCell align="center" style={{fontFamily: 'Omnes', fontSize: 20}}>Regalo</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                            {guests.map((row) => (
                                <TableRow hover key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" 
                                        style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}
                                        onClick={() => navigate(`/?p1=${weddingId}&p2=${row.id}`)}>
                                        {row.name}
                                    </TableCell>

                                    <TableCell align="center">
                                        {row.accept === 'Si' && <AiFillCheckCircle color='green' size={25} /> }
                                        {row.accept === 'No' && <AiFillCloseCircle color='red' size={25} /> }
                                    </TableCell>
                                    <TableCell align="left">{row.dateConfirm}</TableCell>
                                    <TableCell align="center">{row.paxes}</TableCell>
                                    <TableCell align="center">
                                        { row.gift && <AiFillGift color={colors.dorado} size={25} /> }
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </Box>
    );

}

export default AdminScreen;