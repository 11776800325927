import { Grid } from "@mui/material";
import { FaHotel } from 'react-icons/fa';
import { Box } from "@mui/system";
import Subtitles from "../organims/Subtitles";
import { colors } from "../constants/colors";
import { openLink } from "../Utils/Utilities";

const Hoteles = () => {

    const hotel_st = require('../images/hoteles/santa_cruz.jpg');
    const hotel_parque_satelite = require('../images/hoteles/parque_satelite.jpg');
    const hotel_madeira = require('../images/hoteles/madeira.jpg');
    const hotel_fiesta_inn = require('../images/hoteles/fiestainn.jpg');

    return (
        <Box paddingTop={3} sx={{ flexGrow: 1 }}  alignItems="center">

            <Box
                display={'flex'} 
                flexDirection={'row'} 
                justifyContent={'center'}
                alignItems={'center'}>
                <FaHotel color={colors.dorado} size={40} />
                <Box width={15} />
                <Subtitles text={'Hospedaje sugerido'} />
            </Box>

            <Box marginTop={2} marginLeft={3} marginRight={3} textAlign={'center'}>
                Nos permitimos buscar algunas opciones de hospedaje para que puedas considerarlos
            </Box>

            <Box paddingTop={3} sx={{ flexGrow: 1 }}  alignItems="center">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                        <Box 
                            className="cursor" 
                            justifyContent={'center'}
                            textAlign={'center'}
                            onClick={() => openLink(`https://www.booking.com/Share-AOXcf6`)}>
                            <b>Hotel Santa Cruz</b> <br />
                            <img src={hotel_st} 
                                alt='Hotel Santa Cruz'
                                style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}} />
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                        <Box 
                            className="cursor" 
                            justifyContent={'center'}
                            textAlign={'center'}
                            onClick={() => openLink(`https://www.booking.com/Share-GtVGVP`)}>
                            <b>Hotel Parque Satélite</b> <br />
                            <img src={hotel_parque_satelite} 
                                alt='Hotel Parque Satélite'
                                style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box paddingTop={3} sx={{ flexGrow: 1 }}  alignItems="center">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                        <Box 
                            className="cursor" 
                            justifyContent={'center'}
                            textAlign={'center'}
                            onClick={() => openLink(`https://www.booking.com/Share-TLiiFh`)}>
                            <b>Hotel Madeira</b> <br />
                            <img src={hotel_madeira} 
                                alt='Hotel Madeira'
                                style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}} />
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={4} md={6} textAlign={'center'}>
                        <Box 
                            className="cursor" 
                            justifyContent={'center'}
                            textAlign={'center'}
                            onClick={() => openLink(`https://www.booking.com/Share-1WWHBG`)}>
                            <b>Hotel Fiesta Inn Naucalpan</b> <br />
                            <img src={hotel_fiesta_inn} 
                                alt='Hotel Fiesta Inn Naucalpan'
                                style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Hoteles;